import tagmanager from "../../../abstracts/tagmanager"

const skiresort = () => ({
    
    autocompleteSelect(event) {
        tagmanager.event(`skiresort-search`, {
            Category: 'skiresort',
            Skiresort: event.detail.attributes['nameAlias'],
            Federalstate: event.detail.attributes['federalState'],
            Country: event.detail.attributes['country'],
        })
        
        this.$nextTick(() => {
            window.location.href = event.detail.attributes['link']
        })
    },
})

export default skiresort
